.ourClients {
    background-color: #f2f7fa;
}

.ourClientsSlider {
    max-width: 900px;
    margin: auto;

    h4 {
        line-height: 1;
        letter-spacing: 0.3px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        text-transform: none;
        margin-bottom: 30px;
    }

    p {
        color: #818181;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
    }
}

.carousel-control-next {
    right: -92px;

    @media screen and (max-width: 654px) {
        right: -30px;
    }

    &:hover {
        display: flex !important;
    }
}

.carousel-control-prev {
    left: -92px;

    @media screen and (max-width: 654px) {
        left: -30px;
    }

    &:hover {
        display: flex !important;
    }
}

.landing-carousel {
    padding: 0px;

    @media screen and (max-width: 654px) {
        width: 85% !important;
        max-width: 100% !important;
        margin: auto;
    }
}

.carousel-indicators {
    bottom: -40px;
}

.carousel-inner {


    border-radius: 0px 30px 0px 30px;
    // border-right: 6px solid #0b3578;
    // padding: 31px 30px;
    background-color: transparent
}

.carousel-item {
    position: relative;
    // border: 2px solid white;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    padding: 0px;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;


}