.technology {
  font-family: "Poppins", sans-serif;
  background-color: #fca434;
  color: #202020;
  margin-top: 2rem;
  margin-bottom: 1rem;

  text-align: left;
}

.technology .header-section {
  margin-bottom: 10px;

}

.technology .header-section .title {
  position: relative;
  padding-bottom: 14px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
}

.technology .header-section .description {
  font-size: 14px;
  color: #fff;
}

.technology-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  .technology-items {
    align-items: center;
    background-color: #0e0d0d;
    // border: 1px solid #e1e1e1;
    border-radius: 5px 50px 50px 5px;
    border-right: 6px solid #fff;
    color: #000;
    display: flex;
    padding: 0px 7px;
    width: 150px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.30);
    margin-bottom: 10px;

    img {
      height: 20px;
      margin-right: 6px;
    }

    span {
      font-size: 40px;
      margin-right: 6px;
    }

    div {
      font-size: 15px;
      color: #fff;
    }
  }
}

@media only screen and (max-width:438px) {
  .technology-items {
    width: 230px !important;
  }

  div {
    font-size: 20px !important;
  }

  span {

    margin-right: 20px !important;
  }
}